
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');
html,
body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Titillium Web', sans-serif;

 
}

.grecaptcha-badge { 
    visibility: hidden;
  }
  
  


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* COLORS */
:root {
  --color-bg: #121212;
  /* --color-main: rgb(32, 168, 32); */
  --color-main: #00ffff;
  --color-text-white:white;
  --color-phone: rgb(69, 69, 69);
  --color-test: #4d78ed;
}



#dynamicImage0 img{
   /*  object-fit: cover !important; */
    /* height: 100vh !important;
    width: 100% !important; */
  /*   height: 10rem ;
    width: 5rem ; 
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    position: absolute; */
    
    
}

#dynamicImage0 img.active {
    /* position: fixed !important; */
    z-index: 9999 !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    object-fit: cover !important;
    
}


.fullscreen-size {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
   /*  visibility: hidden; */
}

.fullscreen-size img {
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.img-frame {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 1rem;
}


/* FONTS */
h1,h2,h3,h4,h5,h6,button,textarea,p {
    font-family: var(--font-main);  
}
.h1__font-large {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 90px;
}
.h1__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 50px;
}
.h1__font-small {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 40px;
}
.h2__font-large {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 35px;
}
.h2__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 30px;
}
.h2__font-small {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 25px;
}
.h3__font-large {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 20px;
  font-weight: 100;
}
.h4__font-small {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 15px;
}
.h4__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 18px;
}
.p__font-large {

}
.p__font-medium {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 20px;
  
}
.p__font-small {
font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 18px;
}
.p__font-xsmall {
  font-family: var(--font-main);  
  color: var(--color-text-white);
  font-size: 12px;
}







/* CONTAINER SETTINS*/

.flex__center-r {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex__center-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-c {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
}





/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #303030;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 92, 92);
 /*  border-radius: 0.5rem; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00515f;
}






/* NEON BUTTON*/
@import url("https://fonts.googleapis.com/css?family=Raleway");

:root {
  --glow-color: hsl(125, 59%, 48%);
}



.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 900;
  
 /*  letter-spacing: 0.5em; */

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
 /*  margin-right: -0.8em; */
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}



















  .checkbox-wrapper-59 input[type="checkbox"] {
    visibility: hidden;
    display: none;
  }

  .checkbox-wrapper-59 *,
  .checkbox-wrapper-59 ::after,
  .checkbox-wrapper-59 ::before {
    box-sizing: border-box;
  }

  .checkbox-wrapper-59 .switch {
    width: 60px;
    height: 30px;
    position: relative;
    display: inline-block;
    border: 2px solid  rgba(255, 255, 255, 0.446);
    border-radius: 2rem;

  }

  .checkbox-wrapper-59 .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
/*     box-shadow: 0 0 0 2px #777, 0 0 4px #777; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    cursor: pointer;
    /* border: 1px solid white; */
    overflow: hidden;
    transition: 0.2s;
    width: 25px !important; height: 25px;
  }

/*   .checkbox-wrapper-59 .slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #777;
    border-radius: 30px;
    transform: translateX(-56px);
    transition: 0.2s;
  } */
  .checkbox-wrapper-59 .fi-se {
    transform: translateX(0px);
    opacity: 0;
  }
  .checkbox-wrapper-59 .fi-gb {
    transform: translateX(0px);
    opacity: 1;
  }

  .checkbox-wrapper-59 input:checked + .fi-se {
    transform: translateX(30px);
    opacity: 1;
  }
  .checkbox-wrapper-59 input:checked ~ .fi-gb {
    transform: translateX(30px);
    opacity: 0;
  }

/* 
  .checkbox-wrapper-59 input:checked + .slider:before {
    transform: translateX(4px);
    background-color: limeGreen;
  }

  .checkbox-wrapper-59 input:checked + .slider {
    box-shadow: 0 0 0 2px limeGreen, 0 0 8px limeGreen;
  }
 */





















 /* Square Button*/
.navbar__navigation-sqrbtn{
  height: 100%;
}

.navbar__navigation-sqrbtn button{
  background: none;
  border: none;
  cursor: pointer;
 
 
  padding: 0.2rem 0.6rem 0.2rem 0.6rem;
  letter-spacing: 0.05rem;
}
.navbar__navigation-sqrbtn .square-btn {
  box-shadow: inset 0 0 0 2px #f5f5f5;
  /* color: #f7f7f7; */
  
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.navbar__navigation-sqrbtn .square-btn::before, .navbar__navigation-sqrbtn .square-btn::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.navbar__navigation-sqrbtn .square-btn::before {
  border-bottom-width: 3px;
  border-left-width: 3px;
}
.navbar__navigation-sqrbtn .square-btn::after {
  border-top-width: 3px;
  border-right-width: 3px;
}
.navbar__navigation-sqrbtn .square-btn:hover {
  /* color: var(--color-main); */
  background: -webkit-linear-gradient(#344e31, #06ded3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.navbar__navigation-sqrbtn .square-btn:hover::before, .navbar__navigation-sqrbtn .square-btn:hover::after {
  border-color: aqua;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.navbar__navigation-sqrbtn .square-btn:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.navbar__navigation-sqrbtn .square-btn:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

