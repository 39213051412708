.tools {
  height: 200vh;
  width: 100vw;
  background-color: var(--color-bg);
  position: relative;
  overflow: hidden;
  

}

.tools__bgimg {
  position: absolute;  
  top: 0;
  width: 100vw;
  height: 100vh;



}
.tools__bgimg img{
  filter: brightness(0.1);
  
  position: absolute;
  object-fit: cover;
  object-position: 10% 0;
  z-index:  1;
  transform: translate3d(0,0,0);  
}



.tools__info {
  justify-content: center;
 
  height: 100%;
}
.tools__info h3{
  max-width: 40vw;
  margin-left: 5rem;
/*   text-align: left; */
}
.tools__info h3 span{
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
}




@media screen and (max-width: 1000px){
  .tools {
    align-items: center;
  }
  .tools__info {
    max-width: 80vw;
    align-items: center;
  }
  .tools__info h3{
    font-size: 25px;
    margin-left: 0;
    max-width: none;
  }

}

@media screen and (max-width: 500px){
  .tools__info h3{
    font-size: 15px;
  }
}