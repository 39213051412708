/* SECOND HEADER */

  
  
  
.secondheader{
    justify-content: space-around;
    height: 150vh;
    width: 100vw;
	background-color:var(--color-bg);
}
  .secondheader__info{
    text-align: start;
    width: 80vw;
  }
  .secondheader__info h3:first-child{
    color: var(--color-main);
  }
  .secondheader__info h3:nth-child(2){
    color: rgb(0, 255, 145);
  }
  .secondheader__info h3:nth-child(3){
    color: #f1c62c;
  }
  .secondheader__info span{
    color: var(--color-text-white);
  }
  
  
  .secondheader__phone {
    width: 50vw;
    height: 100%;
  }
  .secondheader__phone-phone {
    position: relative;
    height: 300px; width: 150px; 
    border: 6px solid var(--color-phone);
    border-radius: 2rem;
    
    overflow: hidden;
  
    
  }
  
  .secondheader__phone-phone img{
    position: absolute;
    object-fit: cover;
    top: 0; bottom : 0; left : 0; right : 0;
    width: 100%; height: 100%;
    
    
  }
  
  .secondheader__phone-phone #phone-top-bar {
    position: absolute;
    height: 13px; width: 55%;  
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    background-color: var(--color-phone);
    top:-5px; left:calc(50% - 27.5%) ; right:50%;    
    
    
  
  }
  .secondheader__phone-phone #phone-bottom-bar {
    position: absolute;
    height: 5px; width: 60%;  
    background-color: var(--color-phone);
    bottom:1rem; left:calc(50% - 30%) ; right:50%;
    border-radius: 0.5rem;
    
  
  }
  

  
@media screen and (max-width: 650px){
	.secondheader h3{
		font-size: 12px;
	}


}
@media screen and (orientation: landscape) and (max-width:1000px){
    .secondheader h3{
		font-size: 12px;
	}
}
  