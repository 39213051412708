.App {
  text-align: center;
  overflow: hidden;
  

}









