.mobilescroll {
  height: 500vh;
  width: 100vw;
  background-color: var(--color-bg);
  position: relative;
  
  justify-content: space-around;


  
}



.left-col{
  width: 50vw;
  height: 100%;
  inset: 0px auto auto 0px;
}

.mobilescroll__phone-frame {
  margin-top: 25%;
  margin-left: 6rem;
  position: relative;
  
  
 
  background-color: rgba(245, 231, 179, 0);
 
}

.mobilescroll__text-frame {
  margin-top: 25%;
  position: relative;
  width: 40vw; height: 530px;
  
  overflow: hidden;
  background-color: rgba(245, 231, 179, 0);
 
}


.mobilescroll__bgimg {

 
  
}
.mobilescroll__bgimg img{
  width: 100%; height: 530px;
  object-fit: cover;

}



.right-col {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 50vw;
}
.mobilescroll__info{
  position: absolute;
  height: 100%;
  margin-top: 10%;
  /* gap:10rem */

}
.mobilescroll__info-textelement {
  max-width: 40vw;
  min-height: 530px;
}
.mobilescroll__info h3{
  

}
.mobilescroll__info h3 span{
  color: var(--color-main);
}






.left-col-mobile{
  width: 100vw;
  display: none;
/*   opacity: 0.35; */
}
.left-col-mobile .mobilescroll__phone-frame{
  display: flex;
  justify-content: center;
  margin-left: 0;
}
.right-col-mobile{
  display: none;
  position: absolute;      
  top: 0; left: 0; right: 0; bottom: 0;
  width: 100vw;
  height: 100%;
}
.right-col-mobile .mobilescroll__text-frame{
  width: 100vw;
}
.right-col-mobile .mobilescroll__text-frame .mobilescroll__info {
  align-items: center;
  width: 100vw;
}
.right-col-mobile .mobilescroll__info-textelement {
  max-width: 80vw;
}




.textcoloumn {
    position: relative;
    height: 100%;
    width: 50vw;
  
}
.textcoloumn__fixed {
    position: absolute;
/*     background-color: rgba(0, 0, 0, 0.254); */
    width: 50vw !important;
    height: 100vh;
    top:0; right: 0; bottom: 0;
 
}
.textcoloumn__fixed h3{
    position: absolute;
    color: white;
    bottom: -50%;
    height: auto;
    margin: 0;
    width:90% !important;
    font-size: 30px;
}   






















@media screen and (max-width: 900px){
    .textcoloumn {
        position: absolute;      
        top: 0; left: 0; right: 0; bottom: 0;
        width: 100vw;
        height: 100%;
    }
    .textcoloumn__fixed {
        width: 100vw !important;
        left: 0; right:0;
        display: flex;
        justify-content: center;
    }
    .textcoloumn__fixed h3{
        font-size: 15px;
        width: 90vw;
    }
    .textcoloumn__fixed h3{
        
    }
}

@media screen and (max-width: 740px){
    .mobilescroll {
        /* flex-direction: column;
        justify-content: flex-start;
        align-items: center;    */
        
    }
    .left-col {
        /* align-items: center; */
    }

    .mobilescroll__phone-frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-left: 0;
    }
   
}


@media screen and (orientation: landscape) and (max-width:1000px){		
  .mobilescroll__info,
  .mobilescroll__text-frame,
	.mobilescroll__phone-frame {
		margin-top: 0 ;	
	}
}