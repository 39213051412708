.fadeheader {
  height: 400vh;
  width: 100vw;
  background-color: var(--color-bg);
  position: relative;
  overflow: hidden;

}

.fadeheader__bgimg {
  position: absolute;  
  top: 0;
  width: 100vw;
  height: 100vh;



}
.fadeheader__bgimg img{
  filter: brightness(0.1);
  top:0;left:0;right: 0;bottom: 0;
  position: absolute;
  object-fit: cover;
  height: 100%; width: 100%;
  z-index:  1;
  transform: translate3d(0,0,0);  
}



.fadeheader__info {
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.fadeheader__info h3{
  max-width: 80vw;
  
}
.fadeheader__info h3 span{
  color: var(--color-main);
}




@media screen and (max-width: 900px){
  .fadeheader__info h3{
    font-size: 25px;
  }
}
@media screen and (max-width: 500px){
  .fadeheader__info h3{
    font-size: 20px;
  }
}