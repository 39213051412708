
/* HEADER */
.container__header {
    height: 100vh;
    width: 100vw;
    background-color: var(--color-bg);
    min-height: 100vh;
    min-width: 100vw;
}
.header__info {
    height: 100vh;
    width: 100vw; 
    background-color: var(--color-bg);
    z-index: 56;
}




.container__header #title-name{
    /* color: var(--color-main); */
    background: -webkit-linear-gradient(#344e31, #06dec8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header__buttons {
    gap: 2rem;
}






/* FIRST LOADING  */
.header__info-first {
    position: relative;
    /* top:0; left: 0; right: 0; bottom: 0;
    margin: auto; */
}
.header__info #react-icon-logo{            
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: 10;
    
    
}
.header__info-first #react-icon-logo path{
    fill: var(--color-main)
  
}


.loading-circle {
    --p: 0;
    border-radius: 50%;
    width: 400px; height: 400px;
    position: absolute;
    background: conic-gradient(var(--color-main) var(--p,0), 0, transparent);
    transform: translateY(15px);
    
    
  
}
.loading-circle:after {
    content: "";
    position: absolute;

    border-radius: 50%;
    width: calc(100% - 50px) ; height: calc(100% - 50px);
    
    background-color:var(--color-bg);
    
}


.header__info-first .first-h1 {
    background: -webkit-linear-gradient(#344e31, #06ded3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 70px;
    margin-bottom: 100px;
}
.header__info-first .second-h1 {
    background: -webkit-linear-gradient(#e4e4e4, #747474);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 100px;
}




@media screen and (max-width: 900px){
.container__header h1{
    font-size: 50px;
}
}
@media screen and (max-width: 550px){
    .container__header h1{
        font-size: 35px;
    }
    .loading-circle {        
        width: 100vw;height: 100vw;
    }
    .header__info #react-icon-logo{        
        width: 60vw;
        height: 60vw;
        
    }
}
@media screen and (max-width: 370px){
    .container__header h1{
        font-size: 20px;
    }
}



@media screen and (orientation: landscape) and (max-width:1000px){
    .container__header h1{
        font-size: 25px;
    }
    .loading-circle {        
        max-width: 90vh;max-height: 90vh;
    }
    .header__info #react-icon-logo{        
        width: 55vh;
        height: 55vh;
        
    }
    .header__info-first .second-h1 {
        
        margin-top: 1rem;
    }
    
}
  