
:root {
    /* --color-nav: rgb(40, 236, 250); */
    --color-nav: aqua/*  rgb(211, 40, 250) */;
    --color-nav-opac: rgba(0, 255, 255, 0.248) /* rgb(211, 40, 250, 0.24) */;
    --color-nav-dark: rgb(0, 169, 169)/* rgb(147, 28, 174) */;
}
.component__navbar {
    position: fixed;
    top: 0;    left:-147px;/*  0; */
    background-color: rgba(41, 41, 41, 0);
    /* background-color: yellowgreen; */
    /* border-right: 5px solid var(--color-nav-opac); */
    
  /*  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    width: 200px;
    height: 100vh;
    z-index: 600;
    opacity: 0.25;
    padding-top: 1rem;

}
.navbar-logo-container {
    
    position: relative;
    height: 50px;
}
.component__navbar #navbar-logo path{
    fill:var(--color-nav-dark)
}
#navbar-logo {
    position: absolute;
    fill: antiquewhite;
    left: calc(200px - 50px); right: 0;
    margin: auto;
    width: 50px; height: 50px;
    cursor: pointer;
    align-self: flex-end;
}
.navbar__section {
    margin-left: 1rem;
    margin-bottom: 2rem;
}
.navbar__section .section-title-p{
    text-align: left;

}
.navbar__section .section-sub-p{ 
    cursor: pointer;
    margin-left: 2rem;
    justify-content: space-between;
    border-left: 0px solid var(--color-nav);
    padding-left: 0.2rem;
    transition: border-left 100ms ease-in-out;
}
.navbar__section .section-sub-p:hover{ 
    border-left: 3px solid var(--color-nav);
}
.react-icon-navbar {
    width: 20px; height: 20px;    
    margin-right: 1rem;
    color: white;
}
.navbar__button {        
    width: 100%;
}
.navbar__button p{        
    color: white;
   
}

/* flags */
.swe-flag .fis {
    border-radius: 50%;
    width: 50px !important; height: 50px !important;
    
}
/* flags */


@media screen and (max-width: 1000px){
	.navbar__left {
		display: none;
	}
    .component__navbar {
        display: none;
    }
}

