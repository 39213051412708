.component__device {
    max-height: 100vh;
    
}
.device__frame {
    width: 80vw;
    max-width: 900px;
    height: 60vh;    
    background-color: grey;
    position: relative;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    transition: all 0.5s linear;
}
.device__topcamera {
    position: absolute;
    left: 0; right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 0px;
    opacity: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: grey;
    transition: all 0.5s linear;
    z-index: 1;

}
.device__frame_buttons_1,
.device__frame_buttons_2,
.device__frame_buttons_3,
.device__frame_buttons_4 {
    position: absolute;
    background-color: grey;
    transition: all 0.5s linear;
    opacity: 0;
}
.device__frame_buttons_1 {   
    left: -6px; top: 30%;    
    width: 0px;
    height: 50px;
    border-top-left-radius: 0.5rem ;
    border-bottom-left-radius: 0.5rem ;    
}
.device__frame_buttons_2 {    
    left: -6px; top: 40%;    
    width: 0px;
    height: 50px;
    border-top-left-radius: 0.5rem ;
    border-bottom-left-radius: 0.5rem ;
}
.device__frame_buttons_3 {   
    left: -6px; top: 10%;    
    width: 0px;
    height: 100px;
    border-top-left-radius: 0.5rem ;
    border-bottom-left-radius: 0.5rem ;
}
.device__frame_buttons_4 {  
    right: -6px; top: 20%;    
    width: 0px;
    height: 80px;
    border-top-right-radius: 0.5rem ;
    border-bottom-right-radius: 0.5rem ;
}


.device__screen {
    position: absolute;
    top: 15px; bottom: 15px ; left: 15px ; right: 15px;
    background-color: white;
    border-radius: 1rem;
}
.device__screen-mobile {
    display: none;
    position: absolute;
    top: 15px; bottom: 15px ; left: 15px ; right: 15px;
    background-color: rgb(0, 0, 0);
    border-radius: 1rem;
}



.device__keyboard {
    width: 90vw;
    max-width: 1000px;
    height: 30px;
    
    background-color: rgb(216, 216, 216);
    position: relative;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;

    
    transition: all 0.5s linear;
}
.device__keyboard-hatch {
    position: absolute;
    left: 0; right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    height: 35%;
    border-bottom-left-radius: 10rem;
    border-bottom-right-radius: 10rem;
    background-color: rgb(164, 164, 164);
    
}




/* HEADER SITE ON COMPUTER */
.header__column {
    position: relative;
    height: 100%;
    width: calc(100% / 3);
    background-color: rgb(0, 0, 0);
    z-index: 6;
    background-size:cover;
    background-repeat: no-repeat;
    overflow: hidden;
    filter: brightness(0.3);   
    transition: filter 0.5s linear;

}
.header__column-mobile {
    position: relative;        
    background-color: rgb(0, 0, 0);
    z-index: 6;
    background-size:cover;
    background-repeat: no-repeat;
    overflow: hidden;
    
    transition: filter 0.5s linear;
    height: 100%;
    width: 100%;            
}
.header__column::after,
.header__column-mobile::after {
    content: "";
    position: absolute;
  
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.406);  
}
.device__screen-mobile .column1 {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}
.device__screen-mobile .column3 {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem
}

.column2 {
 
}
.column-line {
    width: 0;
    height: 2px;
    background-color: white;
    opacity: 0;
    transition: all 0.5s linear;
}
.header__column:hover {
    filter: brightness(1);    
}
.header__column .column-title {
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 900;    
    opacity: 1;
   
}
.header__column .column-subtitle {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 900;    
    opacity: 0;    
    height: 100px;
    
}
/* .header__column:hover .column-line {
    opacity: 1;
    width: 100%;
} */
.header__column:hover p {
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
}



.column1 .column-line {    
    background-color: #46d4e7;
    z-index: 1;
}
.column1 p { 
    color: #46d4e7;
    z-index: 1;
}

.column2 .column-line {
    background-color: #ffffff;
    z-index: 1;
}
.column2 p {
    color: #ffffff;    
    z-index: 1;
}
.column3 .column-line {
    background-color: #f1c62c; 
    z-index: 1;
}
.column3 p {
    color: #f1c62c;    
    z-index: 1;
}



/* Mobile column */
.column-title-mobile {
    
    margin: 0;
    font-size: 40px;
}
.column-subtitle-mobile {
    
    height: 0;
    font-size: 20px;
    opacity: 0;
   
}






/* .header__column-mobile:hover {
    height:200%;
}
.header__column-mobile:hover .column-subtitle-mobile{
    display: flex;
    opacity: 1;

}

 */






.device__screen__navbar {
    position: absolute;
    top: 0;    left: 0; right: 0;
    width: 100%;
    height: 50px;
    background-color: rebeccapurple;
    z-index: 1;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.device__screen__header img {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}




@media screen and (max-width: 1000px){
    .device__frame {
        border-radius: 2rem;
        width: 60vw;
        height: 70vh;
    }
    .device__screen {
        border-radius: 1.5rem;
        display: none;
    }
    .device__screen-mobile {
        display: flex;
    }
    .device__frame_buttons_1,
    .device__frame_buttons_2,
    .device__frame_buttons_3,
    .device__frame_buttons_4 {
        width: 7px;
        opacity: 1;
    }
    .device__keyboard{
        opacity: 0;
    }

    .column-title {
        font-size: 40px;
        font-weight: 900;
    }   
      
}

@media screen and (max-width: 420px){
    .device__frame {
        height: 55vh;
    }
    .device__screen-mobile {        
        top: 10px; bottom: 10px ; left: 10px ; right: 10px;                
    }
   /*  .device__screen-mobile p{
        font-size: 20px;
    } */
    .device__topcamera{
        height: 30px;
        opacity: 1;        
    }
    .device__frame_buttons_1 {
        width: 0;
        opacity: 0;
    }
    .device__frame_buttons_2,
    .device__frame_buttons_3 {
        width: 5px;
        left: -4px;
    }
    .device__frame_buttons_4 {
        width: 5px;
        right: -4px;
    }
    .column-title-mobile {        
        font-size: 20px;
    }
    .column-subtitle-mobile {               
        font-size: 12px;           
    }
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
    .device__screen {        
        display: flex;
    }
    .device__screen-mobile {
        display: none;
    }
    .header__column .column-title {
        font-size: 15px;
    }
    .header__column .column-subtitle {
        font-size: 15px;
    }
}