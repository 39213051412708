

/* THIRD WEBSITES */
.websites {
  background-color: var(--color-bg);
  height: 150vh;
  width: 100vw;  
  border: 1px solid var(--color-bg);
}



.websites__info {
  /* align-items: flex-start; */
  max-width: 90vw;
}
.websites__info h3:nth-child(2){
  color: rgb(255, 253, 105);
}
.websites__info h3{
	/* text-align: left; */
  }








.websites__container {
	width: 90vw;
	height: 80vh;
	position: relative;
}

.website__columns {
	cursor: pointer;
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
    height: 100%;
    width: auto ;/* calc(100% / 3); */
    background-color: rgb(0, 0, 0);
    /* z-index: 6; */
    background-size:cover;
    background-repeat: no-repeat;
	background-position: 50%;
    overflow: hidden;
    
    transition: filter 0.5s linear;
}
.website-filter {
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	background-color: #000000e4;
	/* background-color: #ffffff00; */
	width: auto; height: 100%;
}
.website-column1{
/* 	
	right: calc(2* 100% / 3 ); */

}
.website-column2{
	left: calc( 100% / 3 );
/* 	right: calc( 100% / 3 ); */

}
.website-column3{
	left: calc(2 *100% / 3 );
/* 	right: calc(0 ); */
}

#website-logo1,
#website-logo2,
#website-logo3 {
	position: absolute;
	top: 0; bottom  : 0;
	margin: auto;
	width: 33%; height: 150px;
	z-index: 100;
	
}
#website-logo1{
	right : calc(2 * 100% / 3 );left: 0;	
}
#website-logo1 path{
	fill: rgb(54, 126, 198);
}
#website-logo2{
	left: 0;right : calc( 100% / 2 );
}
#website-logo3 {
	right :0;left: 0;
}


.website-logo {
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.websites__container:hover .website-logo {
	opacity: 0;
}




.websites__mobile {
	display: none;
}




/* mobile */
.websites__mobile {
	overflow: hidden;
	width: 100%;
	height: 100%;
  }
.websites__mobile .image-gallery-image{
	cursor: pointer;
	width: auto;  
	max-width: 95vw;
	max-height: 90vh;
	object-fit: contain;
	
}


@media screen and (max-width: 1000px){
	.websites__mobile {
		display: flex;
	}
	.websites__container {
		display: none;
	}
}

@media screen and (max-width: 500px){
	.websites__info h3{
		font-size: 15px;	
	}
}

  