
.component__mobilenavbar {
	display: none;
}




/* mobile menu*/
.navbar-small__icon {
	position: fixed;
	top: 30px; right: 20px;
	/* display: none; */
	color: rgb(255, 255, 255);
	height: 40px; width: 40px;
	z-index: 102;
	cursor: pointer;
}









.navbar-small {        
	position: fixed;
	/* display: none; */
	top: 0;    
	right: 0;    
	width: 100vw;    
	height: 100vh;
	background-color: rgb(48, 47, 47)/* var(--color-bg) */;    
	transition: all 0.5s ease-in-out;  
	gap: 1rem;          
	z-index: 101;
	/* clip-path: circle(1% at 100% 0%); */ /* 140% */
}
.navbar-small #react-icon-logo-mobile {
	width: 50px; height: 50px;	
	cursor: pointer;
}

.navbar-small #react-icon-logo-mobile path{
	fill: aqua;
}

.navbar-small-container {
	width: 100%;	
}
.navbar-small-container .no-border{
	cursor: pointer;
	/* align-self: flex-end; */
	/* gap: 2rem; */
	width: 60vw;
	/* margin-left: 5rem; */
	
	justify-content: space-between;
}
.navbar-small-container .section-title-p {
	/* text-align: left;
	margin-left: 2rem; */
}
.navbar-small-container .section-sub-p {
	/* text-align: left; */
}

.navbar-small .no-border{
  border: none;
}
.navbar-small .no-border{
  cursor: pointer;
  font-size: 45px;    
  background-color: rgba(0, 0, 0, 0);
  color: white;    
}
.navbar-small .square-btn{
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  border: 3px solid white;
}





/* HAMBURGER MENU*/
.navbar-small__icon span
{
display: flex;
width: 29px;
height: 2px;
margin-bottom: 5px;
position: relative;
background: #ffffff;
border-radius: 3px;
z-index: 1;
transform-origin: 5px 0px;
transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            opacity 0.55s ease;
}

.navbar-small__icon span:first-child
{
  
  /* transform: rotate(45deg) translate(-3px,-1px) */
}

.navbar-small__icon span:nth-last-child(2)
{    
/*   transform-origin: 0% 100%; */

 
}
.navbar-small__icon span:nth-last-child(1)
{    
  transform-origin: 0% 100%;
  /* transform: rotate(-45deg) translate(0,1px) */
 
}





@media screen and (max-width: 1000px){
	.component__mobilenavbar {
		display: flex;
	}
}


@media screen and (orientation: landscape) and (max-width:1000px){		
	.navbar-small {
		align-items: center;
		overflow-y: scroll;
	}
	.navbar-small .navbar__icon {
		height: 50px;
	}
	.navbar-small .navbar__icon h1{
		margin-top: 0.5rem;
		margin-bottom: 0;

	}
	.navbar-small-container {
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-around;
		width: 90%;
	}
	.navbar-small-container p{
		
	}
	.navbar-small .no-border {
		width: 45vw;
	}
}