

.iphonex {
	width: 20em;
	/* height: 33.5em; */
	height: 600px;
	display: flex;
	position: absolute;
	transform: rotate(0deg);
	transition: all cubic-bezier(0.36, 0.41, 0.38, 1) 0.4s;
	z-index: 10;
}

.iphonex .back {
	display: flex;
	flex: 1;
	margin: 0.2em;
	border-radius: 3em;
	position: absolute;
	z-index: 1;
	width: calc( 100% - 0.6em );
	height: calc( 100% - 0.6em );
	top: 0;
	right: 0;
	box-shadow: 0 0 10em rgba(70, 70, 70, 0.1);
	transition: all 0.4s;
}

.iphonex .front {
	display: flex;
	flex: 1;
	background-color: #292c2d;
	border-radius: 3em;
	margin: 0.2em;
	overflow: hidden;
	position: relative;
	box-shadow: 0 0.1em 0.4em rgba(255, 255, 255, 0.1) inset;
	z-index: 10;
}
.iphonex .screen{
	display: flex;
	flex: 1;
	background-color: #191b1c;
	margin: 0.4em;
	border-radius: 2.6em;
	border: solid 0.2em #121415;
	position: relative;
	z-index: 10;
}
.iphonex .front__line{
	background: linear-gradient(to right, #515455, #454748, #515455);
	position: absolute;
	z-index: 8;
	width: 100%;
	height: 0.5em;
	top: 10%
}
.iphonex .front__line-second{
	top: 90%
}
.iphonex .screen .screen__view{
	display: flex;
	flex: 1;
	margin: 0.6em;
	border-radius: 2em;
	overflow: hidden;
	position: relative;
	width: 100%;  
	
	
}

.iphonex .screen .screen__view img{ 
    height: 100%;
   	position: absolute;
    
}

/*! EACH IMAGE NEEDS TO BE 100% below the image above if more images add one more here with ex 400%   */
.iphonex .screen .screen__view #mobilescroll-img2 {
	top: 100%;
}
.iphonex .screen .screen__view #mobilescroll-img3 {
	top: 200%;
}
.iphonex .screen .screen__view #mobilescroll-img4 {
	top: 300%;
}







.iphonex .screen .screen__front{
	position: absolute;
	width: 50%;
	background-color: #191b1c;
	height: 1.8em;
	border-radius: 0 0 0.9em 0.9em;
	right: 25%;
	top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 0.3em;
	box-sizing: border-box;
	margin-top: 0.5em;
	z-index: 999;
}
.iphonex .screen .screen__front::after,.iphonex .screen .screen__front::before{
	content: "";
	width: 10%;
	height: 50%;
	position: absolute;
	background: transparent;
	top: -0.3em;
	border: solid 0.4em #191b1c;
	border-bottom: 0;
}
.iphonex .screen .screen__front::after{
	left:0.4em;
	transform: translateX(-100%);
	border-left: 0;
	border-radius: 0 0.7em 0 0;
}
.iphonex .screen .screen__front::before{
	right:0.4em;
	transform: translateX(100%);
	border-right: 0;
	border-radius: 0.7em 0 0 0;
}
.iphonex .screen .screen__front-speaker{
	background: #070808;
	border-radius: 0.2em;
	height: 0.35em;
	width: 28%;
}
.iphonex .screen .screen__front-camera{
	height: 0.35em;
	width: 0.35em;
	background: #272727;
	margin-left: .5em;
	border-radius: 50%;
	margin-right: -0.8em;
}
.iphonex .phoneButtons{
	width: 1em;
	height: 6em;
	position: absolute;
	z-index: 2;
	background: linear-gradient(to bottom, #212324, #2b2e31,#212324);
	box-shadow: 0 0 0.4em rgba(255, 255, 255, 0.1) inset;
	border-radius: 2px;
}
.iphonex .phoneButtons-right{
	right: 0;
	top: 30%;
}
.iphonex .phoneButtons-left{
	left: 0;
	top: 15%;
	height: 3em;
}
.iphonex .phoneButtons-left2{
	left: 0;
	top: 25%;
	height: 5em;
}
.iphonex .phoneButtons-left3{
	left: 0;
	top: calc( 25% + 6em);
	height: 5em;
}

@media (max-height: 40em){
	
	.iphonex {
		/* width: 48vh;
		height: 94vh; */
		
	}
	
}

@media screen and (max-width: 730px){
  
    .mobilescroll__bgimg-img {
        filter: brightness(0.3);
    }
}

@media screen and (max-width: 400px){
    .iphonex {
		transform: scale3d(0.5,0.5,0.5);
	}
}


@media screen and (orientation: landscape) and (max-width:1000px){
	.iphonex {
		transform: scale3d(0.5,0.5,0.5) translateY(-200px);
	/* 	transform: translateX(100px); */
	}	
}
@media screen and (orientation: landscape) and (max-height:350px){
	.iphonex {
		transform: scale3d(0.4,0.4,0.4) translateY(-350px);
	/* 	transform: translateX(100px); */
	}	
}