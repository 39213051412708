.container__contact {
    position: relative;
    min-height: 100vh; width:400vw;
    background-color: rgb(41, 41, 41);
    

}
.contact {
    height: 100%;
    width: 100%;
}
.contact__title {
    position: absolute;
    top: 10%; left: 0; right: 0;
    margin: auto auto;
    color: aqua;
 

}
.contact-page {
   
    position: absolute;
    top: 0; bottom: 0; left : 0; right : 0;
    height: 100vh; width:100vw;

    
}
.container__contact .contact__backbtn{
    
    height: 60px;
    
}
.container__contact h2 {
    padding: 0;
    margin: 0;
    height: 60px;
    
}
.container__contact input {
    border: none;
    background: none;
    color: rgb(255, 255, 255);
    height: 60px;
    max-width: 95vw;
    text-align: center;
    font-size: 30px;

}
.container__contact textarea {
    border: none;
    background: none;
    color: white;
    
    text-align: center;
    font-size: 30px;
    max-width: 95vw;
    height: 200px;
    width: 100%;
}
.container__contact input:focus,
.container__contact textarea:focus {
    outline: none;
}



.container__contact button{
    cursor: pointer;
    border: none;
    background: none;
    color: aqua;
        
}

.form-error-message {
    position: absolute;
    left: 0; right: 0;
    bottom: 0;
}






/* LOADING SCREEN */ 
.loadingscreen {
  position: fixed;
  backdrop-filter: blur(10px);
  background-color: rgba(41, 41, 41, 0.866);

  width: 100vw; height: 100vh;
  top: 0; left: 0; right : 0; bottom : 0;
  
  z-index: 601;
  
}
.loadingscreen h5 {
  margin-top: 2rem;
  color: var(--color-second);
  
}
.loadingscreen #icon {  
  height: 50px; width: 50px;  
}
.loadingscreen #icon path{  
    fill: var(--color-main);  
}

.loadingscreen__container {
  position: relative;
}
.loadingscreen__circle {
  position: absolute;
 /*  top: 62px; */
  left: 67px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--color-main);
  
}
.loadingscreen__orbit {
  position: absolute;

  top: -50px;
  left: -50px;
  width: 150px;
  height: 150px;
  border: 2px #ffffff00 dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 2s ease-in-out infinite;
  animation: spin-right 2s ease-in-out infinite;
  
}

@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}










 @media screen and (max-width:500px) {
    .contact__title {
        font-size: 40px;
    }
    .container__contact textarea {
        font-size: 16px;
    }
 }
